<template>
  <v-dialog
    v-model="show"
    width="550"
  >
    <v-card>
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Подтвердить действие
        </div>
      </v-toolbar>
      <div class="pa-6">
        <div class="text-body-1 black--text">
          Удалить все товары?
        </div>
      </div>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5cb7b1"
          dark
          @click="submit"
        >
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props:{
    show:{
      type:Boolean,
      required:true
    }
  },
  methods:{
    close(){
      this.$emit('update:show', false)
    },
    submit(){
      this.$emit('perform', {action:'deleteAllGoods'})
      this.close()
    }
  }
}
</script>